export interface VecuCreatePayload {
  name: string;
}

export interface VecuCreateResponse {
  id: string;
  name: string;
}

export interface VecuDeletePayload {
  vecuNames: string[];
}

export interface VecuDeletionResponse {
  statusCode: number;
  vecuName?: string;
  message: string;
}

export interface VecuBulkDeletionResponse {
  bulkDeletionStatus: VecuDeletionResponse[];
}

export enum VecuStatus {
  CREATING = 'Creating',
  RUNNING = 'Running',
  DELETING = 'Deleting',
  FAILED = 'Failed'
}

export interface VecuStatusDetail {
  message: string;
  code: string;
}

export interface Vecu {
  id: string;
  name: string;
  status: VecuStatus;
  createdAt: string;
  statusDetail?: VecuStatusDetail;
}
