@if (vecus.length === 0) {
    <app-empty-section
        [iconName]="EmptySectionIcon.COFFEE_MAKER"
        [emptySectionScope]="EmptySectionScope.PAGE"
    >
      {{ "VecuList.NoVecus" | translate }}
    </app-empty-section>
} @else {
    <div
        data-cy="vecu-list-container"
        class="vecu-list-container"
    >
        @for (vecu of vecus; track $index) {
            <app-vecu-list-item
                [ngClass]="{
                    'creating': vecu.status === VecuStatus.CREATING,
                    'running': vecu.status === VecuStatus.RUNNING,
                    'deleting': vecu.status === VecuStatus.DELETING,
                    'failed': vecu.status === VecuStatus.FAILED,
                }"
                data-cy="vecu-list-item"
                [vecu]="vecu"
            />
        }
    </div>
}    
