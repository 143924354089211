import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Store } from 'src/app/core/models/classes/abstract.store';
import { Vecu } from '../models/vecu';
import { VecusState } from '../models/vecus-state';

@Injectable({
  providedIn: 'root',
})
export class VecuStore extends Store<VecusState> {
  constructor() {
    super({ vecus: [], isLoading: true, hasError: false, errorStatusCode: 0 });
  }

  get vecus$(): Observable<Vecu[]> {
    return this.state$.pipe(map((state) => state.vecus));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.isLoading));
  }

  get hasError$(): Observable<boolean> {
    return this.state$.pipe(map((state) => state.hasError));
  }
  
  get errorStatusCode$(): Observable<number | undefined> {
    return this.state$.pipe(map((state) => state.errorStatusCode));
  }
}
