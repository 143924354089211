<div
  data-cy="application-dashboard"
  class="root"
  *ngIf="applicationData$ | async as applicationData"
>
  <div class="sticky">
    <app-header-template [itemName]="applicationData.application.appName" [isRowDisplayed]="true">
      <div
        data-cy="delete-application-button-container"
        class="tooltip"
        [matTooltip]="deleteToolTipMessage"
        [matTooltipDisabled]="!isDeleteButtonDisabled"
        matTooltipPosition="left"
      >
        <button
          mat-flat-button
          class="delete-button"
          data-cy="delete-application-button"
          aria-label="Delete application"
          (click)="openDeleteDialog()"
          [disabled]="isDeleteButtonDisabled"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </app-header-template>
  </div>
  <ng-container *ngIf="applicationData$ | async as applicationData">
    <app-content-wrapper
      [isLoading]="applicationData.isLoading"
      [isError]="applicationData.hasError"
    >
      <app-details-expansion
        (eventEmitter)="handleEventEmitter($event, applicationData.application)"
        [title]="'ApplicationDetails.SectionTitle' | translate"
        [description]="'ApplicationDetails.SectionInfo' | translate"
        [menuItems]="menuItems"
        [expanded]="false"
        [hideMenu]="true"
      >
        <app-display-details
          *ngIf="applicationData.application"
          data-cy="application-details"
          translationKey="Application"
          [data]="applicationData.application | convertToTypeLabels"
        ></app-display-details>
      </app-details-expansion>
      <div class="application-dashboard-content">
        <app-deployment-list
          (eventEmitter)="startDeploymentClick()"
          [application]="applicationData.application"
        ></app-deployment-list>
      </div>
    </app-content-wrapper>
  </ng-container>
</div>
