import { FeatureId } from '../../../../shared/stores/config/models/featureId';
import { FeatureConfig } from '../../../../shared/stores/config/models/featureConfig';
import { Method } from '../../../../shared/stores/config/models/method';
import { FeatureSectionConfig } from '../../../../shared/stores/config/models/featureSectionConfig';
import {
  Divider,
  ProjectDashboardDetailsPage,
  ProjectUserListPage,
} from './general';
import { GeneralApi, GeneralFeatureConfig } from './generalApi';
import { AppConfigInstanceType } from '../../../../shared/stores/config/models/appConfigInstanceType';

export const HdkConfigMap = (options: GeneralFeatureConfig<GeneralApi>) => {
  const hdkConfig: any = {
    features: [
      HdkSection,
      {
        id: '12312321',
        featureId: FeatureId.PROJECT_DASHBOARD_PAGE,
        label: 'Applications',
        type: 'FEATURE',
        icon: 'apps',
        path: 'project/:projectId',
        API: {
          getApplications: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications`,
          },
          createApplication: {
            method: Method.POST,
            url: `${options.api.toolchain}/v3/applications`,
          },
          deleteApplication: {
            method: Method.DELETE,
            url: `${options.api.toolchain}/v3/applications`,
          },
          getRepositoryProviderList: {
            url: `${options.api.toolchain}/v3/repositories`,
            method: Method.GET,
          },
          getProviderRepositories: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/repositories/:provider`,
          },
        },
        children: [
          ProjectDashboardDetailsPage,
          ProjectUserListPage,
          Divider,
          HdkSection,
          ApplicationDashboard(options),
          HdkDevices(options),
        ],
      },
      HdkDevices(options),
    ],
  };
  return hdkConfig;
};

const ApplicationDashboard = (
  options: GeneralFeatureConfig<GeneralApi>,
): Partial<FeatureConfig> => {
  return {
    id: '123123',
    featureId: FeatureId.APPLICATION_DASHBOARD_PAGE,
    label: 'Applications',
    icon: 'apps',
    isNested: false,
    path: 'application/:appName',
    API: {
      getApplicationDetails: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications/:appName`,
      },
      deleteApplication: {
        method: Method.DELETE,
        url: `${options.api.toolchain}/v3/applications/:appName`,
      },
      getAppVersions: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
      },
      createDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.POST,
      },
      getDevDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.GET,
      },
      getQaDevices: {
        url: `${options.api.qa}/v3/devices`,
        method: Method.GET,
      },
      getAppDetails: {
        url: `${options.api.toolchain}/v3/applications/:appName`,
        method: Method.GET,
      },
      addVersion: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
        method: Method.POST,
      },
      getDeploymentDev: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.GET,
      },
      getDevHardware: {
        url: `${options.api.dev}/v3/devices/:hwName`,
        method: Method.GET,
      },
      getQaHardware: {
        url: `${options.api.qa}/v3/devices/:hwName`,
        method: Method.GET,
      },
      pipelineBuildRequest: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions/:versionId`,
        method: Method.PUT,
      },
      deleteVersion: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions/:versionId`,
        method: Method.DELETE,
      },
      deleteDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.DELETE,
      },
      startDevQNXDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.PUT,
      },
      stopDevQNXDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.PUT,
      },
    },
  };
};

const HdkSection: Partial<FeatureSectionConfig> = {
  label: 'HDK deployments',
  nonNavigatable: true,
  path: '',
};

const HdkDevices = (
  options: GeneralFeatureConfig<GeneralApi>,
): Partial<FeatureConfig> => {
  return {
    id: '1212121212',
    label: 'Devices',
    icon: 'devices',
    featureId: FeatureId.DEVICE_LIST_PAGE,
    path: 'project/:projectId/device-list',
    API: {
      getDevDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.GET,
      },
      getQaDevices: {
        url: `${options.api.qa}/v3/devices`,
        method: Method.GET,
      },
      devDeviceActionRequest: {
        url: `${options.api.dev}/v3/devices/:hwName/state`,
        method: Method.PUT,
      },
      qaDeviceActionRequest: {
        url: `${options.api.qa}/v3/devices/:hwName/state`,
        method: Method.PUT,
      },
      createRealDevice: {
        url: `${options.api.toolchain}/v3/devices`,
        method: Method.POST,
      },
      deleteRealDevice: {
        url: `${options.api.toolchain}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      deleteSimulatedDeviceDev: {
        url: `${options.api.dev}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      deleteSimulatedDeviceQa: {
        url: `${options.api.qa}/v3/devices/:hwName`,
        method: Method.DELETE,
      },
      downloadDeviceScriptsDev: {
        url: `${options.api.dev}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      downloadDeviceScriptsQa: {
        url: `${options.api.qa}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      downloadVhpcDeviceScripts: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName/scripts`,
        method: Method.GET,
      },
      getDevConnectionPackage: {
        url: `${options.api.dev}/v3/devices/:hwName/connection-package`,
        method: Method.GET,
      },
      getQaConnectionPackage: {
        url: `${options.api.qa}/v3/devices/:hwName/connection-package`,
        method: Method.GET,
      },
      getDevFwUpdates: {
        url: `${options.api.dev}/v3/devices/:hwName/firmware-update`,
        method: Method.GET,
      },
      getQaFwUpdates: {
        url: `${options.api.qa}/v3/devices/:hwName/firmware-update`,
        method: Method.GET,
      },
      updateFwVersionDev: {
        url: `${options.api.dev}/v3/devices/:hwName/firmware-update`,
        method: Method.POST,
      },
      updateFwVersionQa: {
        url: `${options.api.qa}/v3/devices/:hwName/firmware-update`,
        method: Method.POST,
      },
      getApplications: {
        method: Method.GET,
        url: `${options.api.toolchain}/v3/applications`,
      },
      getAppVersions: {
        url: `${options.api.toolchain}/v3/applications/:appName/versions`,
        method: Method.GET,
      },
      createDevDeployment: {
        url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
        method: Method.POST,
      },
      vHPCDeviceActionRequest: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName/state`,
        method: Method.PUT,
      },
      createVhpcDevice: {
        url: `${options.api.vhpc}/v1/vhpc`,
        method: Method.POST,
      },
      getQuota: {
        url: `${options.api.vhpc}/v1/vhpc/quota`,
        method: Method.GET,
      },
      deleteVhpcDevice: {
        url: `${options.api.vhpc}/v1/vhpc/:hwName`,
        method: Method.DELETE,
      },
      getVhpcReleases: {
        url: `${options.api.vhpc}/v1/vhpc-releases`,
        method: Method.GET,
      },
      downloadHwDeviceScript: {
        url: `${options.api.dev}/v3/devices/:hwName/scripts`,
        method: Method.GET,
      },
      getPresignedUrlForAudio: {
        url: `${options.api.observability}/devices/:id/android-audio-debug/url`,
        method: Method.GET,
      },
      getPresignedUrlForHdkTools: {
        url: `${options.api.vhpc}/v1/vhpc-tools`,
        method: Method.GET,
      },
      getGrafanaDashboard: {
        url: `${options.api.grafana}/d/1/device-dashboard?var-aws_device=:deviceName`,
        method: Method.GET,
      },
      bulkDeleteRealDevices: {
        url: `${options.api.dev}/v3/devices`,
        method: Method.DELETE,
      },
      bulkDeleteVhpcDevices: {
        url: `${options.api.vhpc}/v1/vhpc`,
        method: Method.DELETE,
      },
    },
    children: [
      {
        id: '1234567891',
        featureId: FeatureId.DEVICE_DETAILS,
        label: 'Device Details',
        icon: 'task_alt',
        type: AppConfigInstanceType.FEATURE,
        path: ':hwName',
        isNested: true,
        API: {
          getDevice: {
            url: `${options.api.dev}/v3/devices/:deviceId`,
            method: Method.GET,
          },
          getDeviceApplications: {
            url: `${options.api.dev}/v3/devices/:hwName/deployments`,
            method: Method.GET,
          },
          getDevConnectionPackage: {
            url: `${options.api.dev}/v3/devices/:hwName/connection-package`,
            method: Method.GET,
          },
          getAppDetails: {
            url: `${options.api.toolchain}/v3/applications/:appName`,
            method: Method.GET,
          },
          getAppVersions: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications/:appName/versions`,
          },
          getDeploymentDev: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.GET,
          },
          getApplications: {
            method: Method.GET,
            url: `${options.api.toolchain}/v3/applications`,
          },
          createDevDeployment: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.POST,
          },
          deleteDevDeployment: {
            url: `${options.api.dev}/v3/applications/:appName/versions/:versionId/deployments`,
            method: Method.DELETE,
          },
        },
      },
    ],
  };
};
