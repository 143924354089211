<form class="form" [formGroup]="formGroup">
  <div class="content">
    <table class="billing-info-table">
      <tr>
        <td class="billing-info">
          <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
            <span matPrefix class="form-icon">
              <mat-icon>monetization_on</mat-icon>
            </span>
            <mat-label>{{"Project.AddProjectDialog.BillingContact" | translate}}</mat-label>
            <input
              #billingInput
              data-cy="add-project-billing"
              placeholder="{{
                'Project.AddProjectDialog.BillingContact' | translate
              }}"
              formControlName="billingContact"
              matInput
              [matAutocomplete]="billingAuto"
              (input)="filter(billingInput)"
              (focus)="filter(billingInput)"
            />
            <mat-autocomplete
              #billingAuto="matAutocomplete"
              [displayWith]="getUserMail"
            >
              @for (option of filteredUsers; track option.email) {
                <mat-option [value]="option">{{ option.email }}</mat-option>
              }
            </mat-autocomplete>
            <mat-error>
              {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
            </mat-error>
          </mat-form-field>
        </td>
        <td></td>
      </tr>
    </table>
  </div>


  <div class="billing-info-container">
    <div class="billing-information-note">{{ "Project.AddProjectDialog.BillingInformationNote" | translate }}</div>
    <div>
      <div>{{totalPercentage()}} / 100% </div>
    </div>
  </div>
  @if (!percentageValid()) {
    <mat-error class="total-percentage-error" data-cy="total-percentage-error">
      {{ 'Project.AddProjectDialog.ErrorMessage.PercentageExceeded' | translate }}
    </mat-error>
  }
  <div class="billing-entries-container" formArrayName="billingGroups">

    @for (billingGroup of billings.controls; track i; let i = $index) {

      <div class="billing-entry-container">

        <div
          data-cy="delete-billing-container"
          class="delete-btn-container"
        >
          <div data-cy="billing-entry-number" class="billing-entries-num">{{i + 1}}.</div>

          <button
            data-cy="delete-billing-btn"
            *ngIf="billings.controls.length !== 1"
            class="delete-billing-button"
            (click)="removeBillingEntry(i)">
            <mat-icon>delete</mat-icon
            >
          </button>
        </div>

        <div class="content" mat-dialog-content [formGroupName]="i">
          <table class="billing-info-table">
            <tr>
              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <mat-label>{{
                      "Project.AddProjectDialog.Percentage" | translate
                    }}
                  </mat-label>
                  <input
                    data-cy="add-project-percentage"
                    maxlength="5"
                    placeholder="{{
                'Project.AddProjectDialog.Percentage' | translate
              }}"
                    formControlName="percentage"
                    matInput
                  />
                  <mat-error data-cy="add-project-invalid-percentage-pattern"
                             *ngIf="billings.controls[i].get('percentage')?.hasError('pattern')">
                    {{ "General.ErrorMessage.Percentage" | translate }}
                  </mat-error>
                  <mat-error data-cy="add-project-invalid-percentage-required"
                             *ngIf="billings.controls[i].get('percentage')?.hasError('required')">
                    {{ "General.ErrorMessage.Required" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>

              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <mat-label>{{
                      "Project.AddProjectDialog.CostCenter" | translate
                    }}
                  </mat-label>
                  <input
                    data-cy="add-project-cost"
                    placeholder="{{
                'Project.AddProjectDialog.CostCenter' | translate
              }}"
                    formControlName="costCenter"
                    matInput
                  />
                  <mat-error data-cy="add-project-invalid-cost">
                    {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
            </tr>

            <tr>

              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <span matPrefix class="form-icon">
                    <mat-icon>monetization_on</mat-icon>
                  </span>
                  <mat-label>{{
                      "Project.AddProjectDialog.ControllingContact" | translate
                    }}
                  </mat-label>
                  <input
                    #controllingInput
                    data-cy="add-project-ctrl"
                    formControlName="controllingContact"
                    matInput
                    [matAutocomplete]="controllingAuto"
                    (input)="filter(controllingInput)"
                    (focus)="filter(controllingInput)"
                  />
                  <mat-autocomplete
                    [displayWith]="getUserMail"
                    #controllingAuto="matAutocomplete"
                  >
                    @for (option of filteredUsers; track option.email) {
                      <mat-option [value]="option">{{ option.email }}</mat-option>
                    }
                  </mat-autocomplete>
                  <mat-error>
                    {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
                  </mat-error>
                </mat-form-field>
              </td>


              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <mat-label>{{
                      "Project.AddProjectDialog.CostCenterOwner" | translate
                    }}
                  </mat-label>
                  <input
                    #costCenterOwnerInput
                    data-cy="add-project-cost-user"
                    placeholder="{{
                'Project.AddProjectDialog.CostCenterOwner' | translate
              }}"
                    formControlName="costCenterOwner"
                    matInput
                    [matAutocomplete]="costCenterAuto"
                    (input)="filter(costCenterOwnerInput)"
                    (focus)="filter(costCenterOwnerInput)"
                  />
                  <mat-autocomplete
                    #costCenterAuto="matAutocomplete"
                    [displayWith]="getUserMail"
                  >
                    @for (option of filteredUsers; track option.email) {
                      <mat-option [value]="option">{{ option.email }}</mat-option>
                    }
                  </mat-autocomplete>
                  <mat-error data-cy="add-project-cost-user-error">
                    {{ 'Project.AddProjectDialog.ErrorMessage.EmailNotSelected' | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <mat-label>{{
                      "Project.AddProjectDialog.LegalEntity" | translate
                    }}
                  </mat-label>

                  <input
                    data-cy="add-project-entity"
                    placeholder="{{
                'Project.AddProjectDialog.LegalEntity' | translate
              }}"
                    formControlName="legalEntity"
                    matInput
                  />
                  <mat-error>
                    {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td class="billing-info">
                <mat-form-field class="caedge-form-field-outline" ngDefaultControl>
                  <mat-label>{{
                      "Project.AddProjectDialog.WBSElement" | translate
                    }}
                  </mat-label>
                  <input
                    placeholder="{{
                'Project.AddProjectDialog.WBSElement' | translate
              }}"
                    formControlName="wbsElement"
                    matInput
                  />
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
      </div>
    }

  </div>

</form>

<div class="add-billing-container">
  <app-cae-button
    data-cy="add-billing-entry-btn"
    [buttonText]="'Add billing entry'"
    [color]="'primary'"
    [icon]="'add'"
    [disable]="billings.controls.length >= maxBillingEntries"
    (click)="addBillingEntry()">
  </app-cae-button>
</div>


