import { Component, Input } from '@angular/core';
import { Vecu, VecuStatus } from '../../../models/vecu';
import { VecuListItemComponent } from '../vecu-list-item/vecu-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { EmptySectionComponent } from 'src/app/shared/components/empty-section/empty-section.component';
import { EmptySectionScope } from 'src/app/shared/utils/emptySectionScope';
import { EmptySectionIcon } from 'src/app/shared/components/empty-section/models/empty-section';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-vecu-list-content',
  standalone: true,
  imports: [
    CommonModule,
    VecuListItemComponent,
    TranslateModule,
    EmptySectionComponent
  ],
  templateUrl: './vecu-list-content.component.html',
  styleUrl: './vecu-list-content.component.scss'
})
export class VecuListContentComponent {
  @Input({ required: true }) vecus: Vecu[] = [];
  EmptySectionIcon = EmptySectionIcon;
  EmptySectionScope = EmptySectionScope;
  VecuStatus = VecuStatus;
}
