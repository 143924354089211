import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Device } from 'src/app/shared/stores/devices/models/device/device';
import { MatIconModule } from '@angular/material/icon';
import {
  DeviceListApiService,
  SimulatedDeviceAction,
} from 'src/app/features/device-list/services/device-list-api/device-list-api.service';
import { Subject, takeUntil } from 'rxjs';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ApiRecord } from 'src/app/shared/stores/config/models/apiRecord';
import { MatButtonModule } from '@angular/material/button';
import { DeviceType } from '../../../../../../shared/stores/devices/models/deviceType';
import { Quota } from '../../../../../../core/models/interfaces/quota';
import { DeviceStatus } from 'src/app/shared/stores/devices/models/deviceStatus';

@Component({
  selector: 'app-simulated-action-buttons',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './simulated-action-buttons.component.html',
  styleUrls: ['./simulated-action-buttons.component.scss'],
})
export class SimulatedActionButtonsComponent {
  @Input() device!: Device;
  @Input() isConnecting!: boolean;
  @Input() isStopping!: boolean;
  @Input() isBeingCreated!: boolean;
  @Input() deviceActionApiConfigMap?: Record<'Simulated' | 'Vhpc', ApiRecord>;
  @Input() quota!: Quota;

  private readonly unsubscribe$: Subject<void> = new Subject();
  readonly Action = SimulatedDeviceAction;
  readonly DeviceStatus = DeviceStatus;

  constructor(
    private deviceListApiService: DeviceListApiService,
    private snackbarService: SnackbarService,
  ) {}

  deviceActionClick(device: Device, action: SimulatedDeviceAction) {
    if (!this.deviceActionApiConfigMap) {
      return;
    }
    const actionUrl =
      device.instanceType === DeviceType.VHPC
        ? this.deviceActionApiConfigMap.Vhpc
        : this.deviceActionApiConfigMap.Simulated;
    this.deviceListApiService
      .triggerDeviceAction(device.deviceId, action, actionUrl)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (_data) => {
          this.snackbarService.notifyInfo(`${action} request sent`);
        },
        error: () => {
          this.snackbarService.notifyError(`${action} request failed`);
        },
      });
  }
  protected readonly Device = Device;
  protected readonly DeviceType = DeviceType;
}
