import { VecuListComponent } from '../vecu-list.component';
import { GettingStartedVecuComponent } from '../components/pages/getting-started/getting-started-vecu.component';

export enum FeatureId {
    VECU_LIST_PAGE = 'VECU_LIST_PAGE',
    VECU_GETTING_STARTED_PAGE = 'VECU_GETTING_STARTED_PAGE',
};

export const vEcuFeatureIds: Partial<Record<FeatureId, any>> = {
    [FeatureId.VECU_LIST_PAGE]: VecuListComponent,
    [FeatureId.VECU_GETTING_STARTED_PAGE]: GettingStartedVecuComponent,
};
