import { Component, inject } from '@angular/core';
import { SelectedVecuService } from '../../../services/selected-vecu-service/selected-vecu.service';
import { CommonModule } from '@angular/common';
import { CheckDeleteStatusPipe } from '../../../pipes/check-delete-status.pipe';

@Component({
  selector: 'app-delete-multiple-vecus',
  standalone: true,
  imports: [
    CommonModule,
    CheckDeleteStatusPipe
  ],
  templateUrl: './delete-multiple-vecus.component.html',
  styleUrl: './delete-multiple-vecus.component.scss'
})
export class DeleteMultipleVecusComponent {
  private selectedVecuService = inject(SelectedVecuService);

  vecus = this.selectedVecuService.vecus$;
  loading = this.selectedVecuService.loading$;
  deletionResponse = this.selectedVecuService.deleteVecuResponse$;
}
