<div class="device-item-first-row">
  <div
    class="checkmark-icon"
    data-cy="bulk-deletion-checkmark"
  >
    <mat-checkbox
      aria-label="Select device for deletion"
      [checked]="isVecuSelectedSignal()"
      (change)="selectVecuToDelete(vecu)">
    </mat-checkbox>
  </div>
  <div class="start-col">
    <div class="text-container">
      <h4>{{ vecu.name }}</h4>
      <p data-cy="device-status">
        {{ vecu.status }}
      </p>
    </div>
  </div>

  <div class="mid-items">
    <app-chip
      class="chip-column"
      data-cy="vecu-creation-date"
      [label]="vecu.createdAt | customFormatDate"
      [variant]="ChipVariant.NEUTRAL_WHITE"
      [matTooltip]="'VecuList.vEcuCreationDate' | translate"
      [icon]="['event', 'left']"
    />
    @if (vecu.statusDetail) {
      <app-chip
        data-cy="vecu-error-detail-chip"
        [label]="vecu.statusDetail.code"
        [variant]="ChipVariant.ERROR"
        [icon]="['info', 'right']"
        [matTooltip]="vecu.statusDetail.message"
      />
    }
  </div>

  <div class="end-items">
    <div class="command-box">
      <div class="command-text">
        vecu connect {{ vecu.id }}
      </div>
      <div
        tabindex="{{ vecu.id }}"
        class="copy-command-btn" 
        data-cy="copy-to-clipboard"
        (keyup)="copyToClipboard()"
        (click)="$event.stopPropagation(); copyToClipboard()">
        <mat-icon
          matTooltip="{{ 'VecuList.CopyConnectCommand' | translate }}"
          matTooltipPosition="below"
        >content_copy</mat-icon>
      </div>
    </div>
  </div>
</div>
