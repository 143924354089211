import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../../../../shared/components/content-wrapper/content-wrapper.component';
import { DetailsExpansionComponent } from '../../../../../shared/components/details-expansion/details-expansion.component';
import { DisplayDetailComponent } from '../../../../application-dashboard/components/application-details/display-detail.component';
import { HeaderTemplateComponent } from '../../../../../shared/components/header/header-template.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  catchError,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  takeUntil,
} from 'rxjs';

import { FeatureComponent } from '../../../../../core/models/classes/feature.component';
import { DeployedApplication } from '../../../models/deviceApplications';

import { DeviceListApiService } from '../../../services/device-list-api/device-list-api.service';
import { FormControl } from '@angular/forms';
import { EmptySectionComponent } from '../../../../../shared/components/empty-section/empty-section.component';
import { MatAccordion } from '@angular/material/expansion';
import { EmptySectionIcon } from '../../../../../shared/components/empty-section/models/empty-section';
import { DeviceDetailExpansionPanelComponent } from '../device-detail-expansion-panel/device-detail-expansion-panel.component';
import {
  Device,
  DeviceDetail,
  DeviceErrorDetail,
} from '../../../../../shared/stores/devices/models/device/device';

import { DialogHintComponent } from '../../../../workspace-page/components/dialog-hint/dialog-hint.component';
import { DeviceType } from '../../../../../shared/stores/devices/models/deviceType';
import { DeviceInstanceStatus } from '../../../../../shared/stores/devices/models/deviceInstanceStatus';

export type DeviceDetailsApi =
  | 'getDeviceApplications'
  | 'getAppVersions'
  | 'getDevice'
  | 'getApplications'
  | 'getDevConnectionPackage';

@Component({
  selector: 'app-device-list-item-details',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    HeaderTemplateComponent,
    ContentWrapperComponent,
    DetailsExpansionComponent,
    DisplayDetailComponent,
    HeaderTemplateComponent,
    MatButton,
    MatIcon,
    TranslateModule,
    EmptySectionComponent,
    MatAccordion,
    DeviceDetailExpansionPanelComponent,
    DialogHintComponent,
  ],
  templateUrl: './device-list-item-details.component.html',
  styleUrl: './device-list-item-details.component.scss',
})
export class DeviceListItemDetailsComponent
  extends FeatureComponent<DeviceDetailsApi>
  implements OnInit, OnDestroy {
  device$: Observable<Device | null> = of(null);
  deviceData$: Observable<DeviceDetail> = of({} as DeviceDetail);
  errorData: DeviceErrorDetail | undefined = undefined;
  deviceId: string = '';
  applications$: Observable<DeployedApplication[]> = this.fetchDeviceApplications();

  searchFilterControl: FormControl = new FormControl('');

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  isApplicationUnknown = false;
  isDeviceUnknown = false;

  isAddDeploymentDisabledSignal = signal<boolean>(false);
  isDeviceCreatedSignal = signal<boolean>(false);
  isDeviceInactiveSignal = signal<boolean>(true);
  isDeviceLoadingSignal = signal<boolean>(false);

  readonly downloadConnectionInfo = 'DeviceDetails.ConnectionPackage.Info';
  readonly downloadConnectionHint = 'DeviceDetails.ConnectionPackage.Hint';
  readonly downloadButtonName = 'DeviceDetails.ConnectionPackage.Button';

  protected readonly EmptySectionIcon = EmptySectionIcon;
  protected readonly DeviceType = DeviceType;
  protected readonly DeviceInstanceStatus = DeviceInstanceStatus;

  constructor(
    private deviceService: DeviceListApiService,
    private deviceListApiService: DeviceListApiService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initRouteListener();
  }

  private initRouteListener() {
    this.route.params.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((params) => {
      this.deviceId = params['hwName'];
      this.device$ = this.fetchDevice().pipe(
        shareReplay(),
      );
      this.applications$ = this.fetchDeviceApplications().pipe(
        shareReplay(),
      );
    });
  }

  private fetchDeviceApplications(): Observable<DeployedApplication[]> {
    if (!this.API) {
      return of([] as DeployedApplication[]);
    }

    return this.deviceService
      .getDeviceApplications(this.API.getDeviceApplications, this.deviceId)
      .pipe(
        map((apps) => {
          this.isApplicationUnknown = false;
          return apps.deployedApplications;
        }),
        catchError(() => {
          this.isApplicationUnknown = true;
          return of([] as DeployedApplication[]);
        }),
        takeUntil(this.unsubscribe$),
      );
  }

  private fetchDevice(): Observable<Device | null> {
    if (!this.API) return of(null);
    this.isDeviceLoadingSignal.set(true);
    return this.deviceService.getDevice(this.API.getDevice, this.deviceId).pipe(
      map((device) => {
        this.isDeviceUnknown = !device;
        if (device) {
          this.updateDeviceDetailData(device);
        }

        this.isDeviceLoadingSignal.set(false);
        return device;
      }),
      catchError(() => {
        this.isDeviceLoadingSignal.set(false);
        this.isDeviceUnknown = true;
        return of(null);
      }),
      takeUntil(this.unsubscribe$),
    );
  }

  updateDeviceDetailData(device: Device) {
    this.errorData = undefined;
    this.deviceData$ = of({
      deviceId: device.deviceId,
      instanceType: device.instanceType,
      creationDate: device.creationDate,
      scheduledDeletionDate: device.scheduledDeletionDate,
      lastUsedDate: device.lastUsedDate,
      instanceStatus: device.instanceState,
      instanceConnectionStatus: device.instanceConnectionStatus,
      fwVersion: device.fwVersion,
      fwUpdate: device.fwUpdate,
      numOfDeployments: device.numOfDeployments,
      devicePartitionsReporting: device.devicePartitionsReporting,
      devicePartitionsReportingMsg: device.devicePartitionsReportingMsg,
    });

    if (device.ui.message) {
      this.errorData = {
        status: device.ui.status,
        message: device.ui.message,
      };
    }
  }

  downloadConnectionPackage() {
    if (!this.API) {
      return;
    }
    this.deviceListApiService.downloadConnectionPackage(
      this.unsubscribe$,
      this.deviceId,
      this.API.getDevConnectionPackage,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
