import { Device, IDataDevice } from 'src/app/shared/stores/devices/models/device/device';
import { DeviceConnectionStatus } from 'src/app/shared/stores/devices/models/deviceConnectionStatus';
import { DeviceFwStatus } from 'src/app/shared/stores/devices/models/deviceFwStatus';
import { DeviceInstanceState } from 'src/app/shared/stores/devices/models/deviceInstanceState';
import { DeviceInstanceStatus } from 'src/app/shared/stores/devices/models/deviceInstanceStatus';
import { DeviceType } from 'src/app/shared/stores/devices/models/deviceType';

export const inactiveDisconnectReason = 'NEWLY_CREATED';

export const deviceIsInactive = (device: Device) => {
  return (
    device.disconnectedReason === inactiveDisconnectReason &&
    device.instanceType === DeviceType.REAL
  );
};

export const getDeviceTypeTooltipKey = (device: Device) => {
  switch (device.instanceType) {
    case DeviceType.REAL:
      return 'DeviceList.RealDevice';
    case DeviceType.VHPC:
      return 'DeviceList.VECUDevice';
    default:
      return 'DeviceList.FilterKeys.ConnectionStatus.Unknown';
  }
};

export const hasDeviceFwUpdateFailed = (device: Device) => {
  return device.lastFwUpdate?.status === DeviceFwStatus.FAILED;
};

export const isAddDeploymentCheckDisabled = (device: Device) => {
  return (
    device.instanceConnectionStatus !== DeviceConnectionStatus.CONNECTED ||
    device.lastFwUpdate?.status === DeviceFwStatus.IN_PROGRESS ||
    device.instanceState === DeviceInstanceState.STOPPING
  );
};

export const isDeviceConnectedAndRunning = (device: Device) => {
  return (
    device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTED &&
    device.instanceState === DeviceInstanceState.RUNNING &&
    device.instanceStatus === DeviceInstanceStatus.CREATION_SUCCESSFUL
  );
};

export const isDeviceHandlingActions = (device: Device) => {
  return (
    device.instanceConnectionStatus === DeviceConnectionStatus.CONNECTING ||
    device.instanceStatus === DeviceInstanceStatus.CREATE_IN_PROGRESS ||
    device.instanceStatus === DeviceInstanceStatus.DELETE_IN_PROGRESS ||
    device.instanceState === DeviceInstanceState.STOPPING ||
    device.instanceState === DeviceInstanceState.PENDING ||
    (
      device.instanceState === DeviceInstanceState.PENDING &&
      device.instanceConnectionStatus === DeviceConnectionStatus.DISCONNECTED
    )
  );
};

export const isDeviceInactive = (device: Device) => {
  return (
    device.instanceConnectionStatus === DeviceConnectionStatus.DISCONNECTED &&
    device.instanceStatus === DeviceInstanceStatus.CREATION_SUCCESSFUL &&
    device.instanceState !== DeviceInstanceState.PENDING
  );
};

export const isDeviceFailed = (device: Device) => {
  return (
    device.instanceStatus === DeviceInstanceStatus.CREATE_FAILED ||
    device.instanceStatus === DeviceInstanceStatus.DELETE_FAILED
  ) && device.instanceState !== DeviceInstanceState.STOPPING;
};

export const computeDeviceLastUse = (device: IDataDevice): string => {
  if (device.loggedStateChange === undefined) {
    return '';
  } else {
    return device.loggedStateChange.stoppedDevice !== 'NA'
      ? device.loggedStateChange.stoppedDevice
      : device.loggedStateChange.startedDevice;
  }
};
