export const VECU_SORT_KEYS = {
  ALPHABETICAL: 'VecuList.SortKeys.Alphabetical',
  CREATION_DATE: 'VecuList.SortKeys.CreationDate',
} as const;

export type VecuSortKey =
  | typeof VECU_SORT_KEYS.ALPHABETICAL
  | typeof VECU_SORT_KEYS.CREATION_DATE;

export const VECU_STATUS_KEYS = {
  ALL: 'VecuList.VecuStatus.All',
  RUNNING: 'VecuList.VecuStatus.Running',
  CREATING: 'VecuList.VecuStatus.Creating',
  FAILED: 'VecuList.VecuStatus.Failed',
  DELETING: 'VecuList.VecuStatus.Deleting',
} as const;

export type VecuStatusKey =
  | typeof VECU_STATUS_KEYS.ALL
  | typeof VECU_STATUS_KEYS.CREATING
  | typeof VECU_STATUS_KEYS.DELETING
  | typeof VECU_STATUS_KEYS.FAILED
  | typeof VECU_STATUS_KEYS.RUNNING;
