<h2 mat-dialog-title data-cy="create-vecu-dialog-title">{{ 'VecuList.CreateDialog.Title' | translate }}</h2>
<div data-cy="create-new-vecu-dialog" mat-dialog-content class="content">
  <div class="inputs" [formGroup]="createVecuFormGroup">
    <mat-form-field class="caedge-form-field-outline">
      <mat-label>{{
        "VecuList.CreateDialog.DeviceName" | translate
      }}</mat-label>
      <input
        #vecuNameFieldViewChild
        (click)="displayRestrictor(vecuNameFieldRef)"
        data-cy="vecu-name-input"
        formControlName="vecuName"
        [errorStateMatcher]="errorMatcher"
        matInput
        maxlength="{{ maxVecuNameLength }}"
      />
      <mat-hint data-cy="create-vecu-dialog-name-hint" align="start">{{
        "VecuList.CreateDialog.NameRequirements" | translate
      }}</mat-hint>
      <mat-hint align="end">{{ vecuNameField?.value.length }}/{{ maxVecuNameLength }}</mat-hint>
      
      @if(vecuNameField?.hasError('nameRestrictorError')) {
        <mat-error data-cy="restricted-name-error">
          {{ 'General.UnallowedInput' | translate }}
        </mat-error>
      }

      @if(vecuNameField?.hasError('similarNameRestrictorError')) {
        <mat-error data-cy="similar-name-error">
          {{ 'VecuList.CreateDialog.SimilarNameExists' | translate }}
        </mat-error>
      }

      @if(vecuNameField?.hasError('required')) {
        <mat-error>
          {{ 'Project.AddProjectDialog.ErrorMessage.Required' | translate }}
        </mat-error>
      }
    </mat-form-field>
  </div>
  <app-dialog-hint
      data-cy="create-vecu-hint"
      class="connection-banner-container"
      [isWarning]="false"
      message="{{ 'VecuList.CreateDialog.VecuNameToBeUnique' | translate }}"
  />
</div>
<div mat-dialog-actions [align]="'end'">
  <button
    data-cy="cancel-vecu-button"
    mat-flat-button
    mat-dialog-close
  >
    {{ "General.Cancel" | translate }}
  </button>
  <button
    class="confirm-btn"
    data-cy="create-vecu-button"
    mat-flat-button
    [disabled]="!createVecuFormGroup.valid"
    (click)="handleSubmitClick()"
  >
    {{ "VecuList.CreateVecu" | translate }}
  </button>
</div>
