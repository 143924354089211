import {
  GeneralApi,
  GeneralFeatureConfig,
} from 'src/app/core/services/config/models/generalApi';
import { Method } from 'src/app/shared/stores/config/models/method';
import { FeatureId } from './feature-id';
import { AppConfigInstanceType } from 'src/app/shared/stores/config/models/appConfigInstanceType';
import { FeatureSectionConfig } from '../../../shared/stores/config/models/featureSectionConfig';

// will be replaced in the future with an API endpoint to fetch the installer URL
export const VECU_INSTALLER_URL = 'https://artifactory.8675.ccp.continental.exchange/artifactory/refint-generic-local/tools/portable-apps/vecu-tools.zip';


export const VecuConfigMap = (options: GeneralFeatureConfig<GeneralApi>) => {
  const vecuConfig: any = {
    features: [
      VecuSection,
      {
        id: '2323232323',
        label: 'vECUs',
        icon: 'developer_board',
        featureId: FeatureId.VECU_LIST_PAGE,
        path: 'project/:projectId/vecu-list',
        API: {
          getVecus: {
            url: `${options.api.vecu}/v1/vecu`,
            method: Method.GET,
          },
          createVecu: {
            url: `${options.api.vecu}/v1/vecu`,
            method: Method.POST,
          },
          deleteVecu: {
            url: `${options.api.vecu}/v1/vecu`,
            method: Method.DELETE,
          },
        },
        children: [
          {
            id: '1230007891',
            featureId: FeatureId.VECU_GETTING_STARTED_PAGE,
            label: 'vECU Getting Started Page',
            icon: 'task_alt',
            type: AppConfigInstanceType.FEATURE,
            path: 'getting-started',
            isNested: true,
            API: {
              getVecus: {
                url: `${options.api.vecu}/v1/vecu`,
                method: Method.GET,
              },
              createVecu: {
                url: `${options.api.vecu}/v1/vecu`,
                method: Method.POST,
              },
            },
          },
        ],
      },
    ],
  };
  return vecuConfig;
};

const VecuSection: Partial<FeatureSectionConfig> = {
  label: 'vECU Creator',
  nonNavigatable: true,
  path: '',
};
