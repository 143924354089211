@if(
  device.instanceType === DeviceType.VHPC &&
  !isConnecting &&
  !isStopping &&
  !isBeingCreated
) {
  <div class="simulated-actions">
    <button
      data-cy="reboot-device-action-btn"
      class="device-action-btn"
      mat-icon-button
      [matTooltip]="'DeviceList.RestartDevice' | translate"
      [attr.aria-label]="'DeviceList.RestartDevice' | translate"
      [disabled]="device.ui.status !== DeviceStatus.CONNECTED || quota.isQuotaExceeded"
      (click)="deviceActionClick(device, Action.REBOOT)">
      <mat-icon>rotate_left</mat-icon>
    </button>
    
    @if(device.ui.status === DeviceStatus.DISCONNECTED) {
      <button
        mat-icon-button
        data-cy="start-device-action-btn"
        class="device-action-btn"
        [disabled]="quota.isQuotaExceeded"
        [matTooltip]="'DeviceList.StartDevice' | translate"
        [attr.aria-label]="'DeviceList.StartDevice' | translate"
        (click)="deviceActionClick(device, Action.START)">
        <mat-icon>play_arrow</mat-icon>
      </button>
    }

    @if(device.ui.status === DeviceStatus.CONNECTED) {
      <button
        data-cy="stop-device-action-btn"
        class="device-action-btn"
        mat-icon-button
        [matTooltip]="'DeviceList.StopDevice' | translate"
        [attr.aria-label]="'DeviceList.StopDevice' | translate"
        (click)="deviceActionClick(device, Action.STOP)">
        <mat-icon>stop</mat-icon>
      </button>
    }
  </div>
}
