<div class="root">
    <div class="getting-started-top" data-cy="vecu-getting-started-page" @fadeIn>

      <div class="intro-text">
        <div class="header-row">
          <h1>
            {{ "Vecu.Title" | translate }}
            <span class="vecu-span">{{ "Vecu.Creator" | translate }}</span>
          </h1>
          <app-cae-button
            data-cy="go-back-button"
            color="primary"
            [attr.aria-label]="'Vecu.Close' | translate"
            (click)="navigateBack()"
          > 
            {{ "Vecu.Close" | translate }}
          </app-cae-button>
      </div>
        <p class="content-area" [innerHTML]="'Vecu.Description' | translate"></p>

        <div>
          <mat-tab-group class="vecu-tab">
            <mat-tab>
              <ng-template mat-tab-label>
                <span class="vecu-tab-label" data-cy="setup-environment-span">{{ 'Vecu.Setup' | translate }}</span>
              </ng-template>

              <div class="content-area">
                <p [innerHTML]="'Vecu.SetupEnvironment' | translate"></p>

                <mat-vertical-stepper class="vecu-stepper"  [linear]="false" #stepper>
                <mat-step>
                  <ng-template matStepLabel>
                      <span data-cy="get-certificates-span">{{ 'Vecu.SetupSteps.GetCertificate' | translate }}</span>
                  </ng-template>

                  <ul class="execute-list">
                    <li>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step1' | translate }}</li>
                    <app-cae-button
                    data-cy="navigate-to-certificate-page-button"
                    class="navigate-to-certificate-page-button"
                    color="primary"
                    [icon]="'forward'"
                    [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                    (click)="navigateToCertificatesPage()"
                  >
                   {{ "Vecu.BuildSteps.Navigate.CertificateButton" | translate }}
                  </app-cae-button>
                    <li>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step2' | translate }}</li>
                    <li>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step3' | translate }}</li>
                    <li>{{ 'Vecu.SetupSteps.GetCertificateSteps.Step4' | translate }}</li>
                  </ul>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>  
                    <span data-cy="download-installer-span">{{ 'Vecu.SetupSteps.DownloadInstaller' | translate }}</span>
                  </ng-template>
                  <app-cae-button
                    data-cy="download-cli-button"
                    class="download-cli-button"
                    color="primary"
                    [attr.aria-label]="'Vecu.SetupSteps.DownloadCLI' | translate"
                    [icon]="'download'"
                    (click)="downloadInstaller()"
                  >
                    {{ "Vecu.SetupSteps.DownloadCLI" | translate }}
                  </app-cae-button>

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>
                     <span data-cy="execute-installer-span">{{ 'Vecu.SetupSteps.ExecuteInstaller' | translate }}</span>
                  </ng-template>

                  <ul class="execute-list">
                    <li>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step1' | translate }}</li>
                    <li>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step2' | translate }}</li>
                    <li>
                      <div>{{ 'Vecu.SetupSteps.ExecuteCLISteps.Step3' | translate }}</div>
                      <div class="command-box">
                        <span class="aligned-text">{{ 'Vecu.SetupSteps.ExecuteCLISteps.Command' | translate }}</span>
                        <mat-icon
                          class="aligned-icon copy-icon"
                          data-cy="copy-to-clipboard-icon"
                          matTooltip="{{ 'Vecu.SetupSteps.ExecuteCLISteps.Tooltip' | translate }}"
                          matTooltipPosition="right"
                          (click)="copyToClipboard()"
                        >file_copy</mat-icon>
                      </div>
                    </li>
                  </ul>
                </mat-step>
                </mat-vertical-stepper>
              </div>
            </mat-tab>

            <mat-tab data-cy="create-vecu-tab">
              <ng-template  mat-tab-label>
                <span class="vecu-tab-label" data-cy="create-vecu-span">{{ 'Vecu.CreateVECU.Title' | translate }}</span>
              </ng-template>

              <div class="content-area">
                  <p [innerHTML]="'Vecu.CreateVECU.Description' | translate"></p>

                  <app-cae-button
                    data-cy="create-vecu-button"
                    class="create-vecu-button"
                    color="primary"
                    [icon]="'add'"
                    [attr.aria-label]="'Vecu.CreateVECU.ButtonText' | translate"
                    (click)="createVECUButtonClicked()"
                  >
                    {{ "Vecu.CreateVECU.ButtonText" | translate }}
                  </app-cae-button>

              </div>
            </mat-tab>

            <mat-tab data-cy="remopte-connection-tab">
              <ng-template  mat-tab-label>
                <span class="vecu-tab-label" data-cy="build-remote-connection-span">{{ 'Vecu.Remote' | translate }}</span>
              </ng-template>

              <div class="content-area">
                <p [innerHTML]="'Vecu.BuildRemoteConnection' | translate"></p>

                <mat-vertical-stepper  class="vecu-stepper"  #stepper>
                  <mat-step>
                      <ng-template matStepLabel>  {{ 'Vecu.BuildSteps.Navigate.Title' | translate }}</ng-template>

                      <div class="step-description">
                        {{ 'Vecu.BuildSteps.Navigate.Description' | translate }}
                      </div>

                      <app-cae-button
                        data-cy="navigate-to-vecu-page-button"
                        class="navigate-to-vecu-page-button"
                        color="primary"
                        [icon]="'forward'"
                        [attr.aria-label]="'Vecu.BuildSteps.Navigate.Button' | translate"
                        (click)="navigateToVECUPage()"
                      >
                       {{ "Vecu.BuildSteps.Navigate.Button" | translate }}
                      </app-cae-button>

                  </mat-step>

                  <mat-step>
                      <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Select.Title' | translate }}</ng-template>

                      <div class="step-description">
                        {{ 'Vecu.BuildSteps.Select.Description' | translate }}
                      </div>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Copy.Title' | translate }}</ng-template>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Copy.Description' | translate }}
                    </div>
                  </mat-step>

                  <mat-step>
                    <ng-template matStepLabel>{{ 'Vecu.BuildSteps.Run.Title' | translate }}</ng-template>

                    <div class="step-description">
                      {{ 'Vecu.BuildSteps.Run.Description' | translate }}
                    </div>
                  </mat-step>

                </mat-vertical-stepper>
              </div>

            </mat-tab>

          </mat-tab-group>
        </div>
      </div>

    </div>
</div>

